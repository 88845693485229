import React, { useEffect, useState, useRef } from 'react'
import { Container, Modal, Button, Header, Checkbox, Form, Icon, Divider, Segment } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'
import { ajax } from '../../ajax'
import { readLocalStorageUnitData } from '../../common'
import { toast } from 'react-toastify'

import './CustomerAccountSettings.css'

///////////
// TOAST //
///////////

let toastTimeoutHandle = null
const toastLoading = () => {
  clearTimeout(toastTimeoutHandle)
  toastTimeoutHandle = setTimeout(() => {
    toast('Loading ...', { containerId: 'toastPreferences', toastId: 'myToast', type: 'info', autoClose: false })
  }, 1000)
}
const toastLoaded = () => {
  clearTimeout(toastTimeoutHandle)
  toast.dismiss({ id: 'myToast' })
}
const toastSaving = () => {
  clearTimeout(toastTimeoutHandle)
  toast('Saving ...', { containerId: 'toastPreferences', toastId: 'myToast', type: 'info', autoClose: false })
  toast.update('myToast', { containerId: 'toastPreferences', render: 'Saving ...', type: 'info', autoClose: false })
}
const toastSaved = () => {
  clearTimeout(toastTimeoutHandle)
  toast.update('myToast', { containerId: 'toastPreferences', render: 'Preferences saved.', type: 'success', autoClose: 3000 })
}

///////////
///////////
///////////

function CustomerAccountSettings() {
  const navigate = useNavigate()
  const firstInputRef = useRef()

  const [loading, setLoading] = useState(true)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [saving, setSaving] = useState(false)

  const [customerAccountPreferences, setCustomerAccountPreferences] = useState({
    firstName: false,
    lastName: false,
    sendSmsNotifications: false,
    addressLine1: '',
    cityTown: '',
    postcode: '',
    additionalEmail1: '',
    additionalEmail2: '',
    additionalEmail3: ''
  })

  const { ownerEmail, accountId: customerAccountId } = readLocalStorageUnitData()[0]

  useEffect(() => {
    fetchCustomerAccountSettings()
    focusFirstInput()
    return () => {}
  }, [])

  function focusFirstInput() {
    try {
      setTimeout(() => {
        firstInputRef.current.focus()
      }, 100)
    } catch (e) {}
  }

  async function fetchCustomerAccountSettings() {
    setLoading(true)
    toastLoading()
    try {
      const preferences = await ajax.getPreferences({ customerAccountId })
      setCustomerAccountPreferences(preferences.accountContactDetails)
      setLoading(false)
      toastLoaded()
      focusFirstInput()
    } catch (error) {
      console.error(error)
    }
  }

  async function postCustomerAccountSettings(customerAccountPreferences) {
    try {
      setSaving(true)
      toastSaving()
      await ajax.postPreferences({ customerAccountId }, { accountContactDetails: customerAccountPreferences })
      setSaving(false)
      toastSaved()
    } catch (error) {
      console.error(error)
    }
  }

  const CustomCheckbox = ({ id, label, disabled = false }) => (
    <Checkbox
      toggle
      disabled={disabled}
      label={label}
      checked={customerAccountPreferences?.[id]}
      id={id}
      onChange={async (e, { id, checked }) => {
        if (id === 'severeWeatherAlertsEnabled' && checked && customerAccountPreferences?.severeWeatherAlertsEnabled === undefined) {
          // first time user has enabled, so also enable Auto-Force Charge
          setCustomerAccountPreferences({ ...customerAccountPreferences, [id]: checked, autoChargingEnabled: true })
          await postCustomerAccountSettings({ ...customerAccountPreferences, [id]: checked, autoChargingEnabled: true })
        } else {
          setCustomerAccountPreferences({ ...customerAccountPreferences, [id]: checked })
          await postCustomerAccountSettings({ ...customerAccountPreferences, [id]: checked })
        }
      }}
    />
  )

  const CustomInput = ({ id, label, disabled = false, firstInput = false }) => {
    return (
      <>
        <Form.Input
          id={id}
          label={label}
          onChange={(e, { id, value }) => {
            setUnsavedChanges(true)
            console.log('!!!', { id, value })
          }}
        >
          <input value={customerAccountPreferences?.[id]} disabled={disabled} ref={firstInput ? firstInputRef : null} />
        </Form.Input>
      </>
    )
  }

  return (
    <>
      <Modal.Header>
        <Icon name="cogs" />
        &nbsp;&nbsp;&nbsp;Account Details
      </Modal.Header>
      <Modal.Content className="CustomerAccountSettings" scrolling>
        <Segment basic vertical loading={loading || saving}>
          <Header>Account Contact Information</Header>
          <p>Your account contact details.</p>
          <Divider hidden />
          <Container text>
            <Form>
              <Form.Input label="Email Address">
                <input value={ownerEmail} disabled />
              </Form.Input>
              <Form.Group widths="equal">
                <CustomInput id="firstName" label="First Name" firstInput={true} />
                <CustomInput id="lastName" label="Last Name" />
              </Form.Group>
              <Form.Input label="Mobile Phone Number" />
              <div style={{ textAlign: 'right', paddingTop: '0.5em', paddingBottom: '1em' }}>
                <CustomCheckbox id="sendSmsNotifications" label="Receive SMS notifications" />
              </div>
              <Form.Input label="Address line 1" />
              <Form.Input label="City/Town" />
              <Form.Input label="Postcode" />
            </Form>
          </Container>
        </Segment>

        <Divider hidden />
        <Divider />

        <Segment basic vertical loading={loading || saving}>
          <Header>Additional Users</Header>
          <p>
            Optionally, if you would like to grant other people access to your Portal, please enter their email addresses. <br />
            <span style={{ opacity: 0.5 }}>
              <strong>(They will each need to sign up to the Portal afterwards)</strong>
            </span>
          </p>
          <Divider hidden />
          <Container text>
            <Form>
              <Form.Input
                label={
                  <strong>
                    Additional Email 1&nbsp;&nbsp;<span style={{ opacity: 0.4 }}>(Optional)</span>
                  </strong>
                }
              />{' '}
              <Form.Input
                label={
                  <strong>
                    Additional Email 2&nbsp;&nbsp;<span style={{ opacity: 0.4 }}>(Optional)</span>
                  </strong>
                }
              />{' '}
              <Form.Input
                label={
                  <strong>
                    Additional Email 3&nbsp;&nbsp;<span style={{ opacity: 0.4 }}>(Optional)</span>
                  </strong>
                }
              />
            </Form>
          </Container>
        </Segment>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </Modal.Content>
      <Modal.Actions>
        {unsavedChanges ? (
          <>
            <Button
              disabled={loading || saving}
              primary
              onClick={() => {
                // window.alert('Saving!')
                navigate(-1)
              }}
            >
              Save changes
            </Button>
            <Button
              disabled={loading || saving}
              secondary
              onClick={() => {
                navigate(-1)
              }}
            >
              Discard changes
            </Button>
          </>
        ) : (
          <>
            <Button disabled={loading || saving} secondary onClick={() => navigate(-1)}>
              Done
            </Button>
          </>
        )}
      </Modal.Actions>
    </>
  )
}

export { CustomerAccountSettings }
