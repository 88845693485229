import React, { useEffect, useState, useRef } from 'react'
import { Modal, Button, Header, Checkbox, AccordionTitle, AccordionContent, Accordion, Icon, Divider, Segment, Message } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'
import { ajax } from '../../ajax'
import { readLocalStorageUnitData } from '../../common'
import { toast } from 'react-toastify'

import './GatewaySettings.css'

///////////
// TOAST //
///////////

let toastTimeoutHandle = null
const toastLoading = () => {
  clearTimeout(toastTimeoutHandle)
  toastTimeoutHandle = setTimeout(() => {
    toast('Loading ...', { containerId: 'toastPreferences', toastId: 'myToast', type: 'info', autoClose: false })
  }, 1000)
}
const toastLoaded = () => {
  clearTimeout(toastTimeoutHandle)
  toast.dismiss({ id: 'myToast' })
}
const toastSaving = () => {
  clearTimeout(toastTimeoutHandle)
  toast('Saving ...', { containerId: 'toastPreferences', toastId: 'myToast', type: 'info', autoClose: false })
  toast.update('myToast', { containerId: 'toastPreferences', render: 'Saving ...', type: 'info', autoClose: false })
}
const toastSaved = () => {
  clearTimeout(toastTimeoutHandle)
  toast.update('myToast', { containerId: 'toastPreferences', render: 'Preferences saved.', type: 'success', autoClose: 3000 })
}

///////////
///////////
///////////

function GatewaySettings() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [toggleA, setToggleA] = useState(false)
  const [toggleB, setToggleB] = useState(false)

  const [gatewayPreferences, setGatewayPreferences] = useState({
    severeWeatherAlertsEnabled: false,
    autoChargingEnabled: false,
    sendSmsNotifications: false
  })

  const { configId } = readLocalStorageUnitData()[0]

  useEffect(() => {
    fetchGatewaySettings()
    return () => {}
  }, [])

  async function fetchGatewaySettings() {
    setLoading(true)
    toastLoading()
    try {
      const preferences = await ajax.getPreferences({ configId })
      setGatewayPreferences(preferences.gateway)
      setLoading(false)
      toastLoaded()
    } catch (error) {
      console.error(error)
    }
  }

  async function postGatewaySettings(gatewayPreferences) {
    try {
      setSaving(true)
      toastSaving()
      await ajax.postPreferences({ configId }, { gateway: gatewayPreferences })
      setSaving(false)
      toastSaved()
    } catch (error) {
      console.error(error)
    }
  }

  const CustomCheckbox = ({ id, label, disabled = false }) => (
    <Checkbox
      toggle
      disabled={disabled}
      label={label}
      checked={gatewayPreferences?.[id]}
      id={id}
      onChange={async (e, { id, checked }) => {
        if (id === 'severeWeatherAlertsEnabled' && checked && gatewayPreferences?.severeWeatherAlertsEnabled === undefined) {
          // first time user has enabled, so also enable Auto-Force Charge
          setGatewayPreferences({ ...gatewayPreferences, [id]: checked, autoChargingEnabled: true })
          await postGatewaySettings({ ...gatewayPreferences, [id]: checked, autoChargingEnabled: true })
        } else {
          setGatewayPreferences({ ...gatewayPreferences, [id]: checked })
          await postGatewaySettings({ ...gatewayPreferences, [id]: checked })
        }
      }}
    />
  )

  return (
    <>
      <Modal.Header>
        <Icon name="cogs" />
        &nbsp;&nbsp;&nbsp;Gateway Settings
      </Modal.Header>
      <Modal.Content className="GatewaySettings" scrolling>
        <Segment basic vertical loading={loading}>
          <Header>Severe Weather Events</Header>

          <p>We scan for Met Office severe weather alerts containing a power outage warning.</p>

          <div style={{ paddingTop: '1em' }}>
            <CustomCheckbox id="severeWeatherAlertsEnabled" label="Opt-in to Severe Weather Events" />
          </div>

          {gatewayPreferences?.severeWeatherAlertsEnabled && !gatewayPreferences?.autoChargingEnabled && !gatewayPreferences?.sendSmsNotifications ? (
            <>
              <br />
              <Message
                warning
                content={
                  <>
                    Please enable <strong>'Send me SMS notifications'</strong> to be notified when you should Force Charge your Powervault.
                  </>
                }
              />
            </>
          ) : (
            <>
              <br />
            </>
          )}

          <p style={{ paddingLeft: '1em', paddingTop: '1em' }}>
            Powervault can override manual and SMARTSTOR<sup>&trade;</sup> schedules to Force Charge your battery to 100% and give you backup power.
          </p>

          <div style={{ padding: '1em' }}>
            <CustomCheckbox
              id="autoChargingEnabled"
              label="Automatically Force Charge my Powervault"
              disabled={gatewayPreferences?.severeWeatherAlertsEnabled === false || gatewayPreferences?.severeWeatherAlertsEnabled === undefined}
            />
          </div>

          <Accordion className="indented">
            <AccordionTitle active={toggleA} onClick={() => setToggleA(!toggleA)}>
              <Icon name="dropdown" />
              Learn more ...
            </AccordionTitle>
            <AccordionContent active={toggleA}>
              <p>Force Charge starts as soon as the alert is received and returns to Force Normal afterwards.</p>
              <p>Charging is set to 6 hours or when the battery reaches 100%.</p>
              <p>Be ready for a power cut with a full battery, without having to do anything yourself.</p>
              <p>We will send you an SMS to confirm charging and the opportunity to stop the charge event.</p>
            </AccordionContent>
          </Accordion>

          {/* <br />
          <p style={{ paddingLeft: '1em' }}>Please tell us below if you would like to receive SMS notifications.</p>
          <div style={{ padding: '1em' }}>
            <CustomCheckbox
              id="sendSmsNotifications"
              label="Send me SMS notifications"
              disabled={gatewayPreferences?.severeWeatherAlertsEnabled === false || gatewayPreferences?.severeWeatherAlertsEnabled === undefined}
            />
          </div>

          <Accordion className="indented">
            <AccordionTitle active={toggleB} onClick={() => setToggleB(!toggleB)}>
              <Icon name="dropdown" />
              Learn more ...
            </AccordionTitle>
            <AccordionContent active={toggleB}>
              <p>Using Met Office data provides accurate and reliable weather forecasts with power cut predictions.</p>
              <p>If you have opted in to severe weather event Automated Force Charging we will send you an SMS when charging starts.</p>
              <p>If you have opted out, we will send you an SMS to advise you to manually Force Charge your battery.</p>
            </AccordionContent>
          </Accordion> */}

          <Divider hidden />
          <Divider />
        </Segment>

        <Segment basic vertical loading={loading}>
          <Header>Managing your energy during a power cut</Header>

          <p>
            Once you have a fully-charged battery, you will need to manage your power reserve by unplugging or turning off energy-intensive devices and
            appliances.
          </p>
          <div className="appliances">
            <Header as="h4" color="green">
              Standard Appliances
              <Header.Subheader>Use normally</Header.Subheader>
            </Header>
            <div>
              <Icon size="big" circular name="coffee" label="test" />
              <small>Coffee Machine</small>
            </div>
            <div>
              <Icon size="big" circular name="mobile alternate" />
              <small>Phone</small>
            </div>
            <div>
              <Icon size="big" circular name="lightbulb outline" />
              <small>Lights</small>
            </div>
            <div>
              <Icon size="big" circular name="television" />
              <small>Television</small>
            </div>
            <div>
              <Icon size="big" circular name="laptop" />
              <small>Laptop</small>
            </div>
            <div>
              <Icon size="big" circular name="wifi" />
              <small>Internet Router</small>
            </div>
            <div>
              <Icon size="big" circular name="video camera" />
              <small>CCTV</small>
            </div>

            <Header as="h4" color="red">
              Energy-Intensive Appliances
              <Header.Subheader>Consider not using these</Header.Subheader>
            </Header>
            <div>
              <Icon size="big" circular name="car" />
              <small>Car Charging</small>
            </div>
            <div>
              <Icon size="big" circular name="shower" />
              <small>Electric Shower</small>
            </div>
            <div>
              <Icon size="big" circular name="thermometer" />
              <small>Electric Heating</small>
            </div>
          </div>

          <p>
            Your <strong>Live View</strong> shows an estimation of how long the battery energy could last, calculated by analysing the current home energy
            consumption rate, so you can make an informed decision about what you don't need whilst off-grid.
          </p>

          <p>
            You've charged your battery and unplugged any energy-intensive devices but you may want to actively manage the level of energy stored in your
            Powervault battery.
          </p>
          <p>
            The countdown timer on the <strong>Live View</strong> shows an estimation of how long the battery energy could last so you can turn off
            non-essential devices.
          </p>
          <p>The good news is the Powervault battery will continue to charge off-grid if there is good enough solar generation.</p>
          <p>
            In the event of a power cut, stay in touch with your utility company for information on how long the power cut will last, especially for planned
            maintenance scenarios.
          </p>
          <Divider hidden />
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={loading || saving} secondary onClick={() => navigate(-1)}>
          Done
        </Button>
      </Modal.Actions>
    </>
  )
}

export { GatewaySettings }
